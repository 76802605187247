import styled from "styled-components";
import { LinearBg } from "../Common/Linear";
import { DesLayout, DesContentLayout } from "../Common/Layout";

import Contentp2e from "./Contents/ContentP2e";
import ContentMeet from "./Contents/ContentMeet";
import ContentPegasus from "./Contents/ContentPegasus";
import ContentLast from "./Contents/ContentLast";
import ContentMetaverse from "./Contents/ContentMetaverse";

const Content: React.FC = () => {
  return (
    <>
      <DesLayout>
        <LinearBg />
        <Contentp2e />
        <LinearBg right={true} reverse={true} />
        <ContentMeet />
        <LinearBg right={false} reverse={true} />
        <ContentMetaverse />
        <LinearBg right={true} />
        <ContentPegasus />
        <ContentLast />
      </DesLayout>
    </>
  );
};

export default Content;
