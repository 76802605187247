import styled from "styled-components";
import media from "../../../lib/media";
import {
  CommonDesDiv,
  DesH1,
  Despan,
  ImgIcon,
  DesContent,
} from "../../Common/Elements";
import { DesContentLayout } from "../../Common/Layout";

const Contentp2e: React.FC = () => {
  const Click = () => {
    alert("Comming soon");
  };
  return (
    <DesContentLayout>
      <DesContent>
        <P2EContainer>
          <P2EDiv>
            <DesH1>
              Accelerate
              <br /> User Experience of P2E
            </DesH1>
            <Despan>
              The user’s P2E (Play To Earn) experience is the key feature in the
              NXDF ecosystem. In order to offer a proper P2E environment, it
              should start with a decentralized financial service that is close
              to the user’s hand. For this purpose, NXDF has developed a mobile
              wallet and based on it, provides users with a much more
              sophisticated decentralized financial service experience. This
              mobile wallet can store or monetize the assets of the game or
              metaverse that the user has signed up for, even if the user does
              not have access to the game or metaverse. Of course, you can also
              use a mobile wallet integrated with the game by using a plugin or
              SDK. If you would like to know more about this, please contact our
              development team.
            </Despan>
            <P2EImgContainer>
              <P2EImgSep2>
                <a href="https://play.google.com/store/apps/details?id=com.staking4u">
                  <img
                    src={`${process.env.PUBLIC_URL}/image/common/googledlink.png`}
                  />
                </a>
                <a onClick={Click}>
                  <img
                    src={`${process.env.PUBLIC_URL}/image/common/appledlink.png`}
                  />
                </a>
              </P2EImgSep2>
              <P2EImgSep>
                <a href="https://discord.gg/UxAYWbkXyS">
                  <ImgIcon
                    src={`${process.env.PUBLIC_URL}/image/common/icon-discode.png`}
                  />
                </a>
                {/* <a href='https://twitter.com/nxdf16'>
                  <ImgIcon
                    src={`${process.env.PUBLIC_URL}/image/common/icon-telegram.png`}
                  />
                </a> */}
              </P2EImgSep>
            </P2EImgContainer>
          </P2EDiv>
          <DesImgDiv>
            <img
              src={`${process.env.PUBLIC_URL}/image/home/description/sec02-image.png`}
            />
          </DesImgDiv>
        </P2EContainer>
      </DesContent>
    </DesContentLayout>
  );
};

const P2EImgSep = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
  ${media.small} {
  }
`;

const P2EImgSep2 = styled(P2EImgSep)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
  ${media.small} {
    flex-direction: column;
    img {
      margin-bottom: 2rem;
    }
  }
`;

const P2EContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  ${media.desktop} {
    flex-direction: column;
    position: static;
  }
  ${media.small} {
    align-items: center;
    justify-content: center;
  }
`;

const P2EImgContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  ${media.small} {
    align-items: center;
    justify-content: center;
  }
`;

const P2EDiv = styled(CommonDesDiv)`
  margin: 26px 48.4px 36px 0;
  position: relative;
  bottom: 10vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  ${media.desktop} {
    position: static;
  }
  ${media.small} {
    margin: 0px;
    width: 300px;
  }
`;

const DesImgDiv = styled.div`
  img {
    width: 100%;
    min-width: 400px;
    height: 100%;
    position: absolute;
    top: 10vw;
    left: 35vw;
    object-fit: contain;
  }

  ${media.desktop} {
    width: 50%;
    align-self: flex-end;
    position: relative;
    top: 15vw;
    img {
      width: 100%;
      min-width: 500px;
      height: 100%;
      position: static;
      object-fit: contain;
    }
  }
  ${media.small} {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      min-width: 300px;
      width: 80%;
      height: 100%;
      position: static;
      left: 0;
      top: 15vh;
      object-fit: contain;
    }
  }
`;

export default Contentp2e;
