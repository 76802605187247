import styled from "styled-components";
import { useRecoilState } from "recoil";
import { LangState } from "../../atoms";
import { selectLang } from "../../lib/selectLang";
import { SideSheet, Paragraph, CrossIcon } from "evergreen-ui";
import media from "../../lib/media";
import { useState } from "react";
import Navigation from "./Nav";

const SHeader = styled.header`
  width: 100%;
  height: 80px;
  background-color: ${(props) => props.theme.headerColor};
  padding: 18px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 100;
`;

const HeaderLayout = styled.div`
  width: 62.5%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${media.desktop} {
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  max-width: 1920px;
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.desktop} {
    justify-content: center;
  }
`;

const Nav = styled.ul`
  max-width: 1920px;
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  li {
    margin: auto 10px;
  }

  ${media.desktop} {
    display: none;
  }
`;

const Button = styled.div`
  width: 140px;
  height: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: rgba(255, 255, 255, 0.2);
  span {
    line-height: 1.11;
    letter-spacing: -0.54px;
    text-align: left;
    font-weight: bold;
  }
`;

const LangContainer = styled.div`
  height: 0px;
  width: 0px;
`;

const LangBox = styled.div`
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderContainer = styled.div`
  width: 18.75%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Hamburger = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/image/header/nav-hamburger.png");
  ${media.desktop} {
    display: block;
    cursor: pointer;
  }
`;
const HamburgerNav = styled.ul`
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: ${(props) => props.theme.headerColor};

  li {
    margin: 30px auto;
  }
`;

const SideContainer = styled.div`
  position: fixed;
  margin-top: 5rem;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.headerColor};
`;

function Header() {
  const [Lang, setLang] = useRecoilState(LangState);
  const [isShown, setIsShown] = useState(false);
  const [Scroll, setScroll] = useState(true);
  const { HeaderLang } = selectLang(Lang);
  // const ChangeLang = () => {
  //   setLang((prev) => !prev);
  // };

  return (
    <SHeader>
      <HeaderContainer>
        <Hamburger
          onClick={() => {
            setIsShown((prev) => !prev);
            setScroll((prev) => !prev);
            console.log(Scroll);
          }}
        />
        <SideSheet
          position={"left"}
          isShown={isShown}
          width={"100%"}
          shouldCloseOnEscapePress={true}
          shouldCloseOnOverlayClick={false}
          preventBodyScrolling={false}
        >
          <SideContainer>
            <HamburgerNav>
              <Navigation />
            </HamburgerNav>
          </SideContainer>
        </SideSheet>
      </HeaderContainer>
      <HeaderLayout>
        <Wrapper>
          <img
            height='28px'
            src={`${process.env.PUBLIC_URL}/image/header/NXDF_logo.png`}
          />
        </Wrapper>
        <Nav>
          <Navigation />
        </Nav>
      </HeaderLayout>
      <HeaderContainer>
        <Button>
          <span>{Lang ? "English" : "한국어"}</span>
          <img
            src={`${process.env.PUBLIC_URL}/image/common/nav-select-down.png`}
          />
        </Button>
      </HeaderContainer>
    </SHeader>
  );
}
export default Header;
